<template>
  <!--begin::Card-->
  <div>
    <h4 class="mb-10 font-weight-bold text-dark">
      Restaurant Type <br>
      <small>Food Type shows at the top of the page beneath the address showing customers what type of business you are. The food type can be set from a list of options below or can be manually typed in under System Settings -> Edit Restaurant Info.</small>
    </h4>
    <form
      class="form"
      novalidate="novalidate"
      id="restaurant_form"
      ref="form"
      v-on:submit="save"
    >
      <div class="form-group">
        <label>Food Type</label>
        <b-form-select
          name="type"
          ref="type"
          v-model="form.food_type"
          :options="types"
        ></b-form-select>
        <span class="form-text text-muted"
          >What type of food you serving?</span
        >
      </div>
      <button
        :style="{'display': $route.name === 'wizard-restaurant-details' ? 'block' : 'none'}"
        ref="form_submit"
        class="btn btn-primary mt-3 mr-4"
      >
        Save
      </button>
    </form>
  </div>
  <!--end::Card-->
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";
export default {
  name: "RestaurantType",
  data() {
    return {
      types: [],
      form: {
        food_type: null
      },
      isValid: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser" , "currentUserPersonalInfo"
    ]),
  },
  mounted() {
    this.getFoodTypes();
    const form = KTUtil.getById("restaurant_form");
    this.fv = formValidation(form, {
      fields: {
        type: {
          validators: {
            notEmpty: {
              message: "Type is required"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
    //console.log(this.fv.on("core.form.valid"));
    this.fv.on("core.form.valid", () => {
      this.$emit('validation', true);
    });

    this.fv.on("core.form.invalid", () => {
      this.$emit('validation', false);
    });
  },
  methods: {
    getFoodTypes() {
      ApiService.get('business/static/food-types')
      .then(({ data }) => {
        console.log(data.data)
        this.types = data.data;
        this.form.food_type = this.currentUserPersonalInfo.food_type
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
      });
    },
    save() {
      let currentUserId = this.currentUser.id;
      ApiService.post('business/settings/wizard/store/restaurant-type/'+currentUserId, this.form)
      .then(({ data }) => {
          sessionStorage.removeItem("personalInfo");
          this.$store.dispatch(UPDATE_PERSONAL_INFO);
        if  (this.$route.name === 'wizard-restaurant-details') {
          Swal.fire("Success", "Restaurant Type updated successfully", "success");
        }
        return true;
      })
      .catch(({ response }) => {
        return false;
      });
    },
    onSubmit() {
      this.$refs.form_submit.click();
    }
  }
};
</script>
<style scoped>
#app {
text-align: start !important;
}
</style>
